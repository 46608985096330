<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-cash-clock</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Anlässe</v-toolbar-title>

      <v-spacer />
      <PersonInput
        single-line
        v-model="person"
        group="employee"
        class="mr-2"
        hideDetails
        clearable
        label="zuerst LK auswählen"
      ></PersonInput>
      <SearchField
        v-model="search"
        label="Titel, SuS oder Klasse"
      ></SearchField>
    </v-app-bar>

    <v-data-table
      v-if="!$vuetify.breakpoint.xs"
      item-key="id"
      :headers="headers"
      :items="itemsFiltered"
      :item-class="() => 'clickable'"
      :loading="loading"
      @click:row="
        (item) =>
          $router.push({
            name: 'FinnaceEventDetail',
            params: { id: item.id },
          })
      "
    >
      <template v-slot:item.endDate="{ item }">
        <DateValue :value="item.endDate" />
      </template>
      <template v-slot:item.startDate="{ item }">
        <DateValue :value="item.startDate" />
      </template>
      <template v-slot:item.participants="{ item }">
        <PersonChip
          :color="highlightPerson(participant) ? 'primary' : ''"
          v-for="participant in item.participants"
          :value="participant"
          :key="participant.id"
        />
      </template>
    </v-data-table>
    <router-view :search="search"></router-view>
  </v-container>
</template>
<script>
import DateValue from "common/components/DateValue.vue";
import PersonChip from "common/components/PersonChip.vue";
import PersonInput from "common/components/PersonInput.vue";
import SearchField from "@/components/SearchField.vue";

import { searchPerson } from "common/utils/people.js";

export default {
  name: "FinanceEvents",
  components: {
    DateValue,
    PersonChip,
    PersonInput,
    SearchField,
  },
  data() {
    return {
      search: null,
      loading: false,
      headers: [
        { text: "", value: "status" },
        { text: "Beschreibung", value: "description" },
        { text: "Teilnehmer", value: "participants" },
        { text: "Beginn", value: "startDate" },
        { text: "Ende", value: "endDate" },
      ],
      items: [],
      person: null,
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          !this.search ||
          el.description.toLowerCase().includes(this.search.toLowerCase()) ||
          el.participants.some((participant) =>
            searchPerson(participant, this.search)
          )
      );
    },
  },
  watch: {
    person() {
      this.fetchData();
    },
  },
  methods: {
    highlightPerson(participant) {
      if (!this.search) return false;
      if (searchPerson(participant, this.search)) {
        return true;
      }
      return false;
    },
    async fetchData() {
      if (this.person) {
        this.loading = true;
        this.items = await this.apiList({
          resource: "finance/eventoverview",
          query: `person=${this.person.id}`,
        });
        this.loading = false;
      } else {
        this.items = [];
      }
    },
  },
  created() {},
};
</script>
